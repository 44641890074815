import React from 'react';
import Markdown from './markdown';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Section from './section';

const StyledRoleDelimiter = styled.span`
  color: ${props => props.theme.generalAccentColor};
`;

const RoleDelimiter = () => <StyledRoleDelimiter> / </StyledRoleDelimiter>;

const Role = ({role, i}) => (
  <span id={role}>
    { i === 0 ? <></> : <RoleDelimiter/> }
    {role}
  </span>
);

const Employees = ({ employeeEdges }) => (
  <>
    {
      employeeEdges.map((edge, i) => {
        const employee = edge.node;
        const id = employee.frontmatter.name.toLocaleLowerCase().replace(' ', '-');
        const roles = employee.frontmatter.roles;
        return (
          <Section as="article" id={id} key={id}>
            <h2>{employee.frontmatter.name}</h2>
            <p>
              { roles.map((role, i) => <Role key={role} role={role} i={i}/>) }
            </p>
            <Markdown html={employee.html} />
          </Section>
        );
      })
    }
  </>
);

const AboutEmployees = () => (
    <StaticQuery
        query={graphql`
            query {
                employees: allMarkdownRemark(
                    filter: {
                        frontmatter: {
                            type: { eq: "about-employee" }
                            published: { ne: false }
                        }
                    },
                    sort: {
                        fields: [frontmatter___order]
                        order: ASC
                    }
                ) {
                    edges {
                        node {
                            html
                            frontmatter {
                                order
                                name
                                roles
                            }
                        }
                    }
                }
            }
        `}

        render={data => Employees(
            {
                employeeEdges: data.employees.edges
            }
        )}
    />
);

export default AboutEmployees; 