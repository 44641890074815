import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const value = e.currentTarget.checked;
    this.props.setValue(value);
    this.props.onChange(value);
  }

  render() {
    return (
      <label className="valid">
        <div className="input-messages">
          <small className="input-messages__label">{ this.props.label }</small>
        </div>
        <input
          type="checkbox"
          name={this.props.name}
          value={this.props.getValue()}
          checked={this.props.getValue()}
          onChange={this.onChange}
        />
        <div className="checkmark"/>
      </label>
    );
  }
}

Checkbox.defaultProps = {
  onChange: () => {},
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default withFormsy(Checkbox);
