// Based on code from enzian.com.au

import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

class FormsyTextarea extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.state = {
      hasFocus: false,
      showErrorMessage: false,
    };
  }

  onChange(e) {
    this.props.setValue(e.currentTarget.value);
    this.setState(() => {
      if (!this.props.showError() && !this.props.showRequired()) {
        return { showErrorMessage: false };
      }
    });
  }

  onFocus(e) {
    this.setState({ hasFocus: true });
  }

  onBlur(e) {
    this.setState({
      hasFocus: false,
      showErrorMessage: this.props.showError() || this.props.showRequired(),
    });
  }

  render() {
    const className =
      (this.state.showErrorMessage ? 'error ' : '') +
      (this.props.isValid() ? 'valid ' : '') +
      (this.state.hasFocus ? 'focus ' : '') +
      (this.props.isRequired() ? 'required ' : '');

    let error = null;
    if (this.state.showErrorMessage) {
      error = (
        <small className="input-messages__error">{this.props.getErrorMessage()}</small>
      );
    }

    return (
      <label className={className}>
        <div className="input-messages">
          <small className="input-messages__label">{ this.props.label }</small>
          { error }
        </div>
        <textarea
          rows={this.props.rows}
          name={this.props.name}
          value={this.props.getValue() || ''}
          placeholder={this.props.placeholder}
          required={this.props.isRequired()}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      </label>
    );
  }
}

FormsyTextarea.defaultProps = {
  rows: 4,
  placeholder: null,
  required: false,
  readOnly: false,
  disabled: false,
};

FormsyTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default withFormsy(FormsyTextarea);
