import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import Page from '../components/page';
import Section from '../components/section';
import { LayoutRow, MainColumn } from '../utils/layout-row';
import Markdown from '../components/markdown';
import ContactForm from '../components/contact-form';
import AboutEmployees from '../components/about-employees';

const pageTheme = {};

const ContactPage = ({ data, location }) => {
  const introNode = data.intro.edges[0].node;

  return (
    <Page location={location}>
      <ThemeProvider theme={pageTheme}>
        <main>
          <Helmet>
            <title>Contact</title>
            <meta
              name="description"
              content="Send a message to Ari and Michael at Webology or read their profiles. Webology is design studio in Parramatta specialising in brand design, logos and websites."
            />
          </Helmet>
          <Section>
            <LayoutRow>
              <MainColumn>
                <h1>{introNode.frontmatter.title}</h1>
                <Markdown html={introNode.html} />
                <ContactForm/>
              </MainColumn>
            </LayoutRow>
          </Section>
          <Section>
            <LayoutRow>
              <MainColumn>
                <AboutEmployees/>
              </MainColumn>
            </LayoutRow>
          </Section>
        </main>
      </ThemeProvider>
    </Page>
  );
};

export const query = graphql`
  query {
    intro: allMarkdownRemark(
      limit: 1
      filter: { frontmatter: { type: { eq: "contact" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default ContactPage;
